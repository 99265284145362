import React from "react";
import { graphql } from 'gatsby';
import Project from "../components/Project";
import SEO from "../components/SEO";

export default function Projects({ location, data }) {
  const projects = data.projects.nodes;
  return (
    <>
      {/* eslint-disable-next-line */}
      <SEO location={location} title="Projects" description="A collection of my current and past work." />
      <h2 className="page-heading">projects</h2>
      {projects.map((project) => (
        <Project key={project.id} project={project} />
      ))}
    </>
  )
}

export const query = graphql`
  query MyQuery {
    projects: allSanityProject(sort: {fields: _createdAt, order: DESC}) {
      nodes {
        name
        technology
        pixelColor
        id
        projectLink
        description
      }
    }
  }
`;