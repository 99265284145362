import React from "react";
import styled from 'styled-components';
import { BiLink as LinkIcon } from 'react-icons/bi';

const ProjectStyles = styled.div`

  margin-bottom: 7rem;

  h3 {
    transition: all 0.15s ease-in-out;
  }

  h4 {
    margin: 2rem 0;
    font-size: 1.6rem;
    font-weight: 300;
    text-transform: uppercase;
    @media (max-width: 635px) {
      font-size: 1.3rem;
    }
  }

`;

const ProjectHeading = styled.div`

  display:flex;
  align-items: center;

  a {
    display: inline-block;
    /* color: var(--color-white); */
    font-size: 2.8rem;
    letter-spacing: 1.2px;
    @media (max-width: 635px) {
      font-size: 2.4rem;
    }
  }

  .project-link-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    margin-left: 1rem;
    color: var(--color-green-1);
    opacity: 0;
  }

  h3:hover {
    opacity: .75;
  }

  h3:hover + .project-link-icon {
    opacity: 1;
  }

`;

const ProjectPixel = styled.div`
    height: 2.8rem; 
    width: 2.8rem; 
    background-color: ${ props => `var(--color-${props.color})` };
    margin-right: 1.5rem;
    @media (max-width: 635px) {
      height: 2.4rem;
      width: 2.4rem;
    }
`;

export default function Project({ project }) {
  return (
    <>
      <ProjectStyles>
        <ProjectHeading className="project-heading">
          <ProjectPixel color={project.pixelColor}></ProjectPixel>
          <h3>
            <a href={project.projectLink} target="_blank" rel="noreferrer">{project.name}</a>
          </h3>
          <div className="project-link-icon" aria-hidden="true">
            <LinkIcon />
          </div>
        </ProjectHeading>
        <h4>{project.technology}</h4>
        <p>{project.description}</p>
      </ProjectStyles>
    </>
  )
}
